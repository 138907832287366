import { lazy } from 'react'
import { DataNeeded } from "@utils"
import { Role } from "@src/Constants/Role"

const AppRoutes = [
  {
    name: 'Home',
    path: '/home',
    component: lazy(() => import('../../views/dashboard/auth/home')),
    layout: "BlankLayout",
    /*beforeLoad: (data, setData, loadState, setLoadState) => {
      setLoadState(LoadState.LOADED)
    },*/
    exact: true,
    meta: {
      authRoute: false,
      dataNeeded: () => DataNeeded.CompanySettings
    }
  },
  {
    name: 'login',
    path: '/login',
    component: lazy(() => import('../../views/dashboard/auth/login')),
    layout: "BlankLayout",
    roles: [Role.LOGGED_OUT],
    meta: {
      authRoute: false,
      dataNeeded: () => DataNeeded.InvitationData
    }
  },
  {
    name: 'signup',
    path: '/signup',
    component: lazy(() => import('../../views/dashboard/auth/signup')),
    layout: "BlankLayout",
    roles: [Role.LOGGED_OUT],
    meta: {
      authRoute: false,
      dataNeeded: () => DataNeeded.InvitationData
    }
  },
  {
    name: 'guestSignup',
    path: '/guest',
    component: lazy(() => import('../../views/dashboard/auth/signup')),
    layout: "BlankLayout",
    meta: {
      authRoute: false,
      dataNeeded: () => DataNeeded.CompanySettings
    }
  },
  {
    name: 'error-coming-soon',
    path: '/coming-soon',
    component: lazy(() => import('../../views/errors/coming-soon')),
    layout: "BlankLayout"
  },
  {
    name: 'choose-company',
    path: '/choose-company',
    component: lazy(() => import('../../views/dashboard/choose-company')),
    layout: "BlankLayout",
    roles: [Role.USER],
    meta: {
      authRoute: false,
      dataNeeded: () => DataNeeded.CompanySettings
    }
  },
  {
    prefix: "/dashboard",
    nodes: [
      {
        prefix: "c/:companySlug",
        nodes: [
          {
            name: 'dashboard',
            path: 'home',
            component: lazy(() => import('../../views/dashboard/dash')),
            layout: "DashboardLayout",
            roles: [Role.USER],
            meta: {
              companyRequired: true
            }
          },
          {
            name: 'companies',
            path: 'companies',
            component: lazy(() => import('../../views/dashboard/companies')),
            layout: "DashboardLayout",
            roles: [Role.USER],
            meta: {
              companyRequired: true
            }
          },
          {
            name: 'areas',
            path: 'areas',
            component: lazy(() => import('../../views/dashboard/areas')),
            layout: "DashboardLayout",
            roles: [Role.USER],
            meta: {
              companyRequired: true
            }
          },
          {
            name: 'access',
            path: 'access',
            component: lazy(() => import('../../views/dashboard/access')),
            layout: "DashboardLayout",
            roles: [Role.USER],
            meta: {
              companyRequired: true
            }
          },
          {
            name: 'qrCode',
            path: 'qrCode',
            component: lazy(() => import('../../views/dashboard/qrCode')),
            layout: "DashboardLayout",
            roles: [Role.USER],
            meta: {
              companyRequired: true
            }
          },
          {
            name: 'questionnaires',
            path: 'questionnaires',
            component: lazy(() => import('../../views/dashboard/surveys/questionnaire')),
            layout: "DashboardLayout",
            roles: [Role.USER],
            meta: {
              authRoute: false,
              dataNeeded: () => DataNeeded.CompanySettings
            }
          }
        ]
      }
    ]
  },
  {
    name: 'dashboard-no-company',
    exact: true,
    path: '/dashboard',
    component: lazy(() => import('../../views/dashboard/dash')),
    layout: "DashboardLayout",
    roles: [Role.USER],
    meta: {
      companyRequired: false
    }
  },
  {
    name: 'join-company-by-invitation',
    path: '/join-company/:token',
    component: lazy(() => import('../../views/dashboard/join-company')),
    layout: "BlankLayout",
    roles: [Role.USER],
    meta: {
      dataToKeepOnAuth: [
        {
          param: "token",
          as: "invitationToken"
        }
      ]
    }
  }
]
export { AppRoutes }