import icon1 from "@src/assets/images/errors/server1.png"
import { Button } from "reactstrap"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"

const Error500 = () => {

  return (
    <div className={"error-pages error500 container d-flex justify-content-center align-items-center flex-column"}>
      <img src={/** @type string */icon1} alt={"server error"} className={"m-5"} style={{ width: "95px" }} />
      <h1 className={"font-large-5 colored-error-text p-1"}>500</h1>
      <h3 className={"font-medium-5 colored-error-text"}><FormattedMessage id={"errors.500.title"} /></h3>
      <p className={"p-1"}><FormattedMessage id={"errors.500.body"} /></p>
      <Link to="/">
        <Button className={"m-1"} color="primary">
          <FormattedMessage id={"home"} />
        </Button>
      </Link>
    </div>
  )
}

export default Error500