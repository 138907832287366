export const Role = {
  USER: 1,
  MANAGER: 2,
  ADMIN: 3,
  GUEST: 4,
  LOGGED_OUT: 5
}

export const getRoleKeyName = (role) => {
  const roleName = {
    [Role.USER]: 'role.user',
    [Role.MANAGER]: 'role.manager',
    [Role.ADMIN]: 'role.admin',
    [Role.GUEST]: 'role.guest',
    [Role.LOGGED_OUT]: 'role.logged_out'
  }
  return roleName[role] ?? role
}

export function getRoleTypes() {
  return [
    {
      id: Role.USER,
      keyName: getRoleKeyName(Role.USER)
    },
    {
      id: Role.MANAGER,
      keyName: getRoleKeyName(Role.MANAGER)
    },
    {
      id: Role.GUEST,
      keyName: getRoleKeyName(Role.GUEST)
    },
    {
      id: Role.LOGGED_OUT,
      keyName: getRoleKeyName(Role.LOGGED_OUT)
    },
    {
      id: Role.ADMIN,
      keyName: getRoleKeyName(Role.ADMIN)
    }
  ]
}