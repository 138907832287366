import { lazy } from "react"
import { AppRoutes as DashboardRoutes } from './Dashboard'
import { ErrorRoutes } from './Errors'
import { matchPath, Route } from "react-router-dom"
import { Role } from "@src/Constants/Role"

// ** Document title
const TemplateTitle = "%s - SSCA"

// ** Default Route
const DefaultRoute = "/home"

// ** Merge Routes
let Routes = [
  {
    path: "/ModeTablette",
    component: lazy(() => import("../../views/pages/modeTablette/ModeTabletteConfig")),
    layout: "VerticalLayout",
    meta: {
      guest: true,
      slugRoute: false
    }
  },
  {
    path: "/:slug/ModeTablette",
    component: lazy(() => import("../../views/pages/modeTablette/ModeTabletteHome")),
    layout: "VerticalLayout",
    meta: {
      guest: true,
      slugRoute: true
    }
  },

  {
    path: "/:slug/ModeTabletteQuiz",
    component: lazy(() => import("../../views/pages/modeTablette/GetCompanyQuiz")),
    layout: "VerticalLayout",
    meta: {
      guest: true,
      slugRoute: true
    }
  },
  {
    path: "/:slug/ModeTablettePairing",
    component: lazy(() => import("../../views/pages/paireTabletteQrScanner/ChooseIdTablet")),
    layout: "VerticalLayout",
    meta: {
      guest: true,
      slugRoute: true
    }
  },
  {
    path: "/:slug/ModeTabletteRegister",
    component: lazy(() => import("../../views/pages/modeTablette/VisitorRegisterForm")),
    layout: "VerticalLayout",
    meta: {
      guest: true,
      slugRoute: true
    }
  },
  {
    path: "/:slug/login",
    component: lazy(() => import("../../views/pages/authentification/Login-v1")),
    layout: "VerticalLayout",
    meta: {
      authRoute: true,
      slugRoute: true
    }
  },
  {
    path: "/:slug/guest/login",
    component: lazy(() => import("../../views/pages/guestUser/Login-Guest-v1")),
    layout: "VerticalLayout",
    meta: {
      authRoute: true,
      slugRoute: true
    }
  },
  {
    path: "/:slug/forgotpassword",
    component: lazy(() => import("../../views/pages/authentification/ForgotPassword")),
    layout: "VerticalLayout",
    meta: {
      authRoute: true,
      slugRoute: true
    }
  },
  {
    path: "/:slug/authedUserQuiz",
    component: lazy(() => import("../../views/pages/authedUser/authedUserQuiz")),
    layout: "VerticalLayout",
    meta: {
      slugRoute: true
    }
  },
  {
    path: "/:slug/GuestUserQuiz/:quizToken?",
    component: lazy(() => import("../../views/pages/authedUser/guestQuiz")),
    layout: "VerticalLayout",
    meta: {
      authRoute: true,
      slugRoute: true
    }
  },
  {
    path: "/passwordreset/:resetToken",
    component: lazy(() => import("../../views/pages/authentification/PasswordReset")),
    layout: "VerticalLayout",
    meta: {
      authRoute: true,
      slugRoute: false
    }
  },
  {
    path: "/verifyemail/:verifyEmailToken",
    component: lazy(() => import("../../views/pages/authentification/VerifyEmail")),
    layout: "VerticalLayout",
    meta: {
      authRoute: true,
      slugRoute: false
    }
  },
  {
    path: "/:slug/register",
    component: lazy(() => import("../../views/pages/authentification/Register-v1")),
    layout: "VerticalLayout",
    meta: {
      authRoute: true,
      slugRoute: true
    }
  },
  {
    path: "/:slug/additionalInfo",
    component: lazy(() => import("../../views/pages/authentification/AdditionalInfo")),
    layout: "VerticalLayout",
    meta: {
      slugRoute: true
    }
  },
  {
    path: "/:slug/verifyInfo",
    component: lazy(() => import("../../views/pages/authentification/VerifyInfo")),
    layout: "VerticalLayout",
    meta: {
      slugRoute: true
    }
  },
  {
    path: "/home",
    component: lazy(() => import("../../views/LandScreen")),
    layout: "VerticalLayout",
    meta: {
      authRoute: true,
      slugRoute: false
    }
  },
  {
    path: "/:slug",
    component: lazy(() => import("../../views/pages/authentification/AuthIntro")),
    layout: "VerticalLayout",
    meta: {
      authRoute: true,
      slugRoute: true
    }
  }
  // {
  //   path: "/:slug/home",
  //   component: lazy(() =>
  //     import("../../views/pages/authentification/Register-v1")
  //   ),
  //   layout: "VerticalLayout"
  // },
  // {
  //   path: "/:slug/private",
  //   component: lazy(() => import("../../views/pages/Home")),
  //   layout: "VerticalLayout"
  // },
  // {
  //   path: "/error",
  //   component: lazy(() => import("../../views/Error")),
  //   layout: "VerticalLayout"
  // }
]

Routes = []

Routes.push(...DashboardRoutes)
Routes.push(...ErrorRoutes)

export function getRoutes() {
  const result = []

  for (const [key, value] of Object.entries(Routes)) {
    result.push(
      <Route key={`route-${  key}`} path={value.path} element={value.component} />
    )
  }
  return <Routes>{result}</Routes>
}

export function reduceRoutes(routes, prefix = "") {
  const result = []

  for (const [key, value] of Object.entries(routes)) {
    if (value.nodes) {
      result.push(...reduceRoutes(value.nodes, prefix === "" ? value.prefix : (value.prefix === "" ? prefix : `${prefix}/${value.prefix}`)))
    } else {
      if (prefix !== "") {
        let separator = ""
        if (value.path !== "") {
          separator = "/"
        }
        value.path = `${prefix}${separator}${value.path}`
      }
      result.push(value)
    }
  }

  return result
}

export function getRouteByPath(path) {
  const index = Routes.findIndex((route) => route.path === path)

  return index !== -1 ? Routes[index] : null
}

export const checkPagePermissionByUserState = (path, user) => {

  const pathObject = getRouteByPath(path)
  console.log(pathObject)

  if (!pathObject || !pathObject?.roles) {
    return true
  }

  console.log(pathObject?.roles)
  if (pathObject?.roles?.length > 0) {
    return pathObject?.roles.includes(user?.role ?? Role.LOGGED_OUT)
  }

  return true
}

export const checkPagePermissionByCompany = (path, user, companySlug) => {

  const pathObject = getRouteByPath(path)
  console.log(pathObject, path)

  if (!companySlug || !pathObject || !pathObject?.meta?.companyRequired) {
    return true
  }

  const companiesSlugs = user?.userCompanies?.map((relation) => relation.company.slug) ?? []

  return companiesSlugs.includes(companySlug)
}

Routes = reduceRoutes(Routes)

export { DefaultRoute, TemplateTitle, Routes }
