import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import loadingErrorImg from '@src/assets/images/pages/error.svg'

import '@styles/base/pages/page-misc.scss'

const LoadingError = ({retry}) => {
  let retryMode = "function"
  if (!retry) {
    retry = () => {}
  } else {
    if (typeof retry === 'string') {
      retryMode = 'redirection'
    }
  }
  return (
    <div className='misc-wrapper'>
      <a className='brand-logo' href='/'>
        {/* your SVG logo code goes here */}
      </a>
      <div className='misc-inner p-2 p-sm-3'>
        <div className='w-100 text-center'>
          <h2 className='mb-1'>Data Loading Error ☹️</h2>
          <p className='mb-2'>Oops! Something went wrong while loading your data. Please try again later.</p>
          {retryMode === "redirection" ? <Button.Ripple tag={Link} to={retry} color='primary' className='btn-sm-block mb-2'>
              Retry
            </Button.Ripple> : <Button.Ripple onClick={retry} color='primary' className='btn-sm-block mb-2'>
              Retry
            </Button.Ripple>
          }
          <img className='img-fluid d-block' src={/** @type string */loadingErrorImg} alt='Loading error page' />
        </div>
      </div>
    </div>
  )
}

export default LoadingError