import { getToken } from "../Base/HTTP"
import { getCurrentUser } from "../Services/UserService"
import { LoadState } from "@utils"
import { logout } from "@src/Services/SecurityService"

export const SET_USER = 'SET_USER'
export const USER_LOGOUT = 'USER_LOGOUT'
export const IS_USER_LOADING = 'IS_USER_LOADING'
export const NOTIFY_AUTH_CHANGE = 'NOTIFY_AUTH_CHANGE'

export const setUser = (authUser) => ({
  type: SET_USER,
  payload: authUser
})

export const isUserLoading = (loading) => ({
  type: IS_USER_LOADING,
  payload: loading
})

export const notifyAuthChange = (nextRoute = null) => ({
  type: NOTIFY_AUTH_CHANGE,
  nextRoute
})

export const userLogout = () => ({
  type: USER_LOGOUT
})

export const fetchAndStoreCurrentUser = async (dispatch, setLoadState) => {
  const token = getToken()
  if (token) {
    getCurrentUser({extend: true}).then(response => {
      if (response?.data) {
        dispatch(setUser(response?.data))
        setLoadState(LoadState.LOADED)
      } else {
        logout()
        setLoadState(LoadState.LOADED)
      }
    }).finally(() => dispatch(isUserLoading(false)))
    return
  }
  dispatch(isUserLoading(false))
  setLoadState(LoadState.LOADED)
}