import {
  SET_USER,
  USER_LOGOUT,
  IS_USER_LOADING, NOTIFY_AUTH_CHANGE
} from "../Actions/AuthActions"

const initialState = {
  currentCompany: null,
  authUser: {},
  isUserLoading: true,
  triggerAuthChange: 0,
  nextRoute: null
}

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        authUser: action.payload
      }
    case USER_LOGOUT:
      return {
        ...state,
        authUser: {},
        triggerAuthChange: state.triggerAuthChange + 1,
        nextRoute: null
      }
    case IS_USER_LOADING:
      return {
        ...state,
        isUserLoading: action.payload
      }
    case NOTIFY_AUTH_CHANGE:
      return {
        ...state,
        triggerAuthChange: state.triggerAuthChange + 1,
        nextRoute: action.nextRoute
      }
    default:
      return {
        ...state,
        ...action
      }
  }
}

export default AuthReducer