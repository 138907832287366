import {ScaleLoader} from 'react-spinners'

const SpinnerComponent = () => {
    return (
        <div className='fallback-spinner-effect'>
            <ScaleLoader height='100px' width='20px' radius='10px' color="#06b5d7" />  
        </div>
    )
}

export default SpinnerComponent