import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import icon1 from '@src/assets/images/errors/404.png'
import { FormattedMessage } from "react-intl"

const Error404 = () => {
  return (
    <div className={"error-pages error404 container d-flex justify-content-center align-items-center flex-column"}>
      <p className={"pt-2"}><FormattedMessage id={"errors.404.body"} /></p>
      <h1 className={"font-large-2 colored-error-text p-1"}><FormattedMessage id={"errors.404.title"} /></h1>
      <img src={/** @type string */icon1} alt={"server error"} className={"m-1"} style={{ maxWidth: "537px" }} />
      <Link to="/">
        <Button className={"m-1"} color="primary">
          Home
        </Button>
      </Link>
    </div>
  )
}
export default Error404
