import { lazy } from "react"

export const ErrorRoutes = [
  {
    name: 'error-coming-soon',
    path: '/coming-soon',
    component: lazy(() => import('../../views/errors/coming-soon')),
    layout: "BlankLayout"
  },
  {
    name: 'error-404',
    path: '/404',
    component: lazy(() => import('../../views/errors/404')),
    layout: "BlankLayout"
  },
  {
    name: 'error-500',
    path: '/500',
    component: lazy(() => import('../../views/errors/500')),
    layout: "BlankLayout"
  },
  {
    name: 'error-fetch',
    path: '/fetch-error',
    component: lazy(() => import('../../views/errors/fetch-data')),
    layout: "BlankLayout"
  },
  {
    name: 'error-400',
    path: '/400',
    component: lazy(() => import('../../views/errors/400')),
    layout: "BlankLayout"
  },
  {
    name: 'error-403',
    path: '/403',
    component: lazy(() => import('../../views/errors/403')),
    layout: "BlankLayout"
  },
  {
    name: 'error-expired',
    path: '/expired',
    component: lazy(() => import('../../views/errors/expired')),
    layout: "BlankLayout"
  }
]