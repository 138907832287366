const ComingSoon = () => {
    return (
        <div style={{
          width: "100%",
          backgroundColor: "#000000",
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "999999999"
        }}>
            <img src={"/media/coming-soon.jpg"} alt={"coming soon"} style={{width: "100%"}}/>
        </div>
    )
}

export default ComingSoon