// ** Checks if an object is empty (returns boolean)
import axios from "axios"
import { notifyAuthChange } from "@src/Actions/AuthActions"
import { useSelector } from "react-redux"
import Error404 from "@src/views/errors/404"
import Error500 from "@src/views/errors/500"
import ComingSoon from "@src/views/errors/coming-soon"
import LoadingError from "@src/views/LoadingError"
import Error403 from "@src/views/errors/403"
import Error400 from "@src/views/errors/400"
import ErrorExpired from "@src/views/errors/expired"
import Spinner from "@components/spinner/Loading-spinner"
import { useState } from "react"
import { Redirect } from "react-router-dom"
import { Routes } from "@src/router/routes"
import { Axios, request } from "@src/Base/HTTP"
import { HttpMethod } from "@src/Constants/HttpMethod"
// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('authToken')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each user regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export async function fetchCompanySetting(slug) {
  const company = {}
  const response = await axios.post(`${process.env.REACT_APP_REDIRECT_URL}/${slug}/getCompanySettings`, {
    withCredentials: true
  })
  company.settings = response.data

  return company
}

export async function validateInvitationToken(invitationToken) {
  return await request(`${process.env.REACT_APP_REDIRECT_URL}/api/v2/invitations/validate`, {
    invitationToken,
    withCredentials: true
  }, HttpMethod.POST)
}

export async function acceptInvitation(invitationToken) {
  return await Axios.getInstance().post(`${process.env.REACT_APP_REDIRECT_URL}/api/v2/invitations/accept`, {
    invitationToken,
    withCredentials: true
  })
}

export const LoadState = {
  LOADING: 0,
  LOADED: 1,
  FAILED: -1
}

export const ErrorState = {
  ComingSoon: "coming-soon",
  Expired: "expired",
  NotFound: 404,
  InternalServerError: 500,
  Forbidden: 403,
  BadRequest: 400
}

export const getRoute = (name) => {
  const searchInOneLevel = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (typeof routes[i].prefix !== 'undefined') {
        const search = searchInOneLevel(routes[i].nodes)
        if (search !== null) return search
      } else {
        if (routes[i].name === name) {
          return routes[i]
        }
      }
    }
    return null
  }
  return searchInOneLevel(Routes)?.path
}

export const buildTo = (routeName, location = null, params = {}) => {
  let routePath = getRoute(routeName)
  if (routePath === undefined) {
    return getRoute("error-coming-soon") ?? "404"
  }
  // convert params into a part of a route
  const paramPaths = Object.keys(params).map(key => `:${key}`)
  for (let i = 0; i < paramPaths.length; i++) {
    routePath = routePath.replace(paramPaths[i], params[Object.keys(params)[i]])
  }
  if (location !== null) {
    return routePath + location.search
  }
  return routePath
}

export const redirectTo = (history, routeName, location = null) => {
  history.push(buildTo(routeName, location))
}

export const redirectToComponent = (routeName, location = null) => {
  console.log("errrrrrrr", routeName)
  return <Redirect to={buildTo(routeName, location)}/>
}

export const DataNeeded = {
  CompanySettings: 1,
  InvitationData: 2
}

export const formatUsername = (username, loginMode) => {
  return {
    type: loginMode === "email" ? "email" : "phone",
    value: username
  }
}

export const handleFormErrors = (response, getValues, setError, exception) => {
  if (response?.data?.errors) {
    const fields = Object.keys(getValues())
    const originalErrors = response.data.errors
    const errors = Object.fromEntries(
      Object.entries({ ...originalErrors }).filter(
        ([k, _v]) => fields.includes(k)
      )
    )
    const otherErrors = Object.fromEntries(
      Object.entries({ ...originalErrors }).filter(
        ([k, _v]) => !fields.includes(k)
      )
    )
    if (Object.keys(errors).length) {
      const keys = Object.keys(errors)
      for (let i = 0; i < keys.length; i++) {
        setError(keys[i], {
          type: "manual",
          message: errors[keys[i]]
        })
      }
      return
    }

    if (otherErrors.length) {
      exception(otherErrors[Object.keys(otherErrors)[0]])
    }
  }
  exception()
}

export const afterLogin = (response, dispatch, setLoading, setErrorMessage, intl, nextUrl = null) => {
  if (!response || response.status !== 202) {
    setLoading(false)
    setErrorMessage({
      value: response?.data?.error || intl.formatMessage({id: "something_wrong_happened"}),
      isAnId: false
    })
    return
  }
  if (response?.data && response?.data?.token) {
    localStorage.setItem("token", response.data.token)
    dispatch(notifyAuthChange(nextUrl ?? buildTo("choose-company")))
  }
}

/**
 * @typedef {Object} User
 *
 * @property {number} id - The user's unique ID.
 * @property {string} email - The user's email address.
 * @property {string} firstName - The user's first name.
 * @property {string} lastName - The user's last name.
 * @property {string} phone - The user's phone number.
 * @property {string} indicator - The user's indicator.
 * @property {number} role - The user's role.
 * @property {Array} userCompanies - The user's associated companies.
 * @property {string} contactLanguage - The language the user wants to be contacted in.
 * @property {number} createdAt - The timestamp when the user was created.
 */

/**
 *
 * @returns {User|undefined}
 */
export const getAuthUserData = () => {
  // noinspection JSCheckFunctionSignatures
  return useSelector((state) => state.AuthReducer.authUser)
}

/**
 *
 * @param {number|string} code
 * @param {boolean} redirect
 * @returns {(function(): *)|*|void}
 */
export const handleError = (code = 404, redirect = false) => {
  if (redirect) {
    switch (code) {
      case "soon":
        return redirectToComponent("error-coming-soon")
      case "expired":
        return redirectToComponent("error-expired")
      case 500:
        return redirectToComponent("error-500")
      case 403:
        return redirectToComponent("error-403")
      case 400:
        return redirectToComponent("error-400")
      default:
        return redirectToComponent("error-404")

    }
  } else {
    switch (code) {
      case "soon":
        return ComingSoon
      case "expired":
        return ErrorExpired
      case 500:
        return Error500
      case 403:
        return Error403
      case 400:
        return Error400
      default:
        return Error404
    }
  }
}

export function addParamToRelativeURL(relativeUrl, params) {
  const urlParts = relativeUrl.split("?")
  const path = urlParts[0]
  const queryString = urlParts[1] || ""
  const searchParams = new URLSearchParams(queryString)
  for (const [key, value] of Object.entries(params)) {
    searchParams.append(key, value)
  }
  return `${path}?${searchParams.toString()}`
}

export function returnComponentWithLoadState(component, loadState, setTrigger) {
  if (loadState === LoadState.LOADED) {
    return component()
  } else if (loadState === LoadState.FAILED) {
    return <LoadingError retry={() => setTrigger(prev => prev + 1)} />
  } else if (loadState === ErrorState.Expired || loadState === ErrorState.BadRequest) {
    const ErrorComponent = handleError(loadState)
    return <ErrorComponent/>
  } else {
    return <Spinner />
  }
}

/**
 * Creates the states of loadState and trigger;
 * {@link LoadState} defines the state of a request (progress, error...), trigger is used to re-call or retry the request.
 * @return {{loadState: unknown, setLoadState: (value: unknown) => void, setTrigger: (value: (((prevState: number) => number) | number)) => void, trigger: number}}
 */
export function prepareLoadStates(initialLoadState = null) {
  const [loadState, setLoadState] = useState(initialLoadState)
  const [trigger, setTrigger] = useState(0)
  return { loadState, setLoadState, trigger, setTrigger }
}

export function getProfilePictureUrl({ firstName, lastName, pdpUrl }) {
  return (Boolean(pdpUrl) && pdpUrl !== "") ? pdpUrl : `https://ui-avatars.com/api/?background=0D8ABC&color=fff&bold=true&name=${firstName}+${lastName}`
}

/**
 * used for setting texts to the object of ServerTable from "react-strap-table"
 * @param intl
 * @returns {{entries: *, search: *, show: *, to: *, showing: *}}
 */
export function getTextsTranslationOfTable(intl) {
  return {
    show: intl.formatMessage({id: "table.show"}),
    entries: intl.formatMessage({id: "table.entries"}),
    search: intl.formatMessage({id: "table.search"}),
    showing: intl.formatMessage({id: "table.showing"}),
    to: intl.formatMessage({id: "table.to"})
  }
}

export function getCurrentCompanyRelation() {
  // noinspection JSCheckFunctionSignatures
  return useSelector((state) => state.AuthReducer.currentCompanyRelation)
}

export function setCurrentCompanyRelation(dispatch, companyRelation) {
  dispatch({
    type: null,
    currentCompanyRelation: companyRelation
  })
}

export function adjustIsoDateToCurrentTimeZone(isoString) {
  return new Date(isoString)
}