import icon1 from "@src/assets/images/errors/400.png"
import icon1_fr from "@src/assets/images/errors/400-fr.png"
import { Button } from "reactstrap"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"

const Error400 = () => {
  const icon = localStorage.getItem("i18nextLng") === "fr-FR" ? icon1_fr : icon1
  return (
    <div className={"error-pages error400 container d-flex justify-content-center align-items-center flex-column"}>
      <img src={/** @type string */icon} alt={"server error"} className={"m-2"} style={{ maxWidth: "838px" }} />
      <Link to="/">
        <Button className={"m-1"} color="primary">
          <FormattedMessage id={"home"} />
        </Button>
      </Link>
    </div>
  )
}

export default Error400