import icon1 from "@src/assets/images/errors/expired.svg"
import { Button } from "reactstrap"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"

const ErrorExpired = () => {

  return (
    <div className={"error-pages errorExpired container d-flex justify-content-center align-items-center flex-column"}>
      <img src={/** @type string */icon1} alt={"server error"} className={"m-5"} style={{ maxWidth: "500px" }} />
      <h3 className={"font-medium-5 colored-error-text"}><FormattedMessage id={"errors.expired.title"} /></h3>
      <p className={"p-1"}><FormattedMessage id={"errors.expired.body"} /></p>
      <Link to="/">
        <Button className={"m-1"} color="primary">
          <FormattedMessage id={"home"} />
        </Button>
      </Link>
    </div>
  )
}

export default ErrorExpired