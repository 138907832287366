import { HttpMethod } from '../Constants/HttpMethod'
import { request } from '../Base/HTTP'
import { generateSearchURL } from '../Util/ServiceUtil'
import { ErrorState, LoadState, validateInvitationToken } from "@utils"

export async function registration(data) {
  return await request('/api/v2/auth/register', data, HttpMethod.POST)
}

export async function updateUser(data) {
  return await request(`/api/v2/user/${  data?.id}`, data, HttpMethod.PUT)
}

export async function deleteUser(id) {
  return await request(`/api/v2/user/${  id}`, {}, HttpMethod.DELETE)
}

export async function getCurrentUser(data = {}) {
  const urlParams = generateSearchURL(data)
  return await request(`/api/v2/user/me?${  urlParams}`)
}

export async function getAllUsers(data = {}) {
  const urlParams = generateSearchURL(data)
  return await request(`/api/v2/users?${  urlParams}`)
}

export async function getUser(data = {}) {
  const urlParams = generateSearchURL(data)
  return await request(`/api/v2/user/${  data?.id  }?${  urlParams}`)
}

export function getValidateInvitation(invitationToken, setInvitationData, setLoadState) {
  if (invitationToken === null || invitationToken === "") {
    setInvitationData(null)
    setLoadState(LoadState.LOADED)
  } else {
    setLoadState(LoadState.LOADING)
    validateInvitationToken(invitationToken).then((response) => {
      const data = response.data
      if (!data || !data.companyData) {
        if (response.status === 400) {
          if (response.data.message === "INVITATION_TOKEN_EXPIRED") {
            setLoadState(ErrorState.Expired)
          } else {
            setLoadState(ErrorState.BadRequest)
          }
        } else {
          setLoadState(LoadState.FAILED)
        }
        return
      }
      data.token = invitationToken
      setInvitationData(data)
      setLoadState(LoadState.LOADED)
    })
  }
}