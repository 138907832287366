import { userLogout } from '../Actions/AuthActions'
import { request } from '../Base/HTTP'
import { HttpMethod } from '../Constants/HttpMethod'
import { googleLogout } from "@react-oauth/google"

export async function login(data) {
  return await request("/api/v2/auth/login", data, HttpMethod.POST)
}

export function logout(dispatch = null) {
  localStorage.removeItem("token")
  googleLogout()
  if (dispatch) {
    dispatch(userLogout())
  }
}